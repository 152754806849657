import * as React from "react"
import { Link, graphql } from "gatsby"
import Nav from "../components/nav"
import PostCard from "../components/post_card"
import Header from "../components/header"


const BlogPage = ({ data }) => {
    const blog = data.allContentfulBlog.edges
    return (
      <main className="bg-abx-light-grey">
        <title>Alchemy Box - Blog</title>
        <Nav colour="grey" default_state='blog' />
        <Header title="Learn something new today" logoText="Blog" colour="pink" />
        {blog.map(({node: post}, idx) => (
            <div key={idx}>
                <PostCard title={post.title} description={post.description} date={post.createdAt} img={post.titleImage} />
            </div>
        ))}
        </main>
    )
}

export default BlogPage

export const query = graphql`
  query {
    allContentfulBlog {
        edges {
        node {
            title
            tags
            createdAt(formatString: "DD MMMM YYYY")
            description
            id
            titleImage {
                title
                gatsbyImageData
            }
        }
        }
    }
  }
  


`
