import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"

const PostCard = (props) => {
  const { title, description, date, img } = props
  return (
    <div>
      <div className="flex flex-row justify-center sm:mx-10 mx-4 font-poppins flex-nowrap">
        <div className="bg-white my-8 flex flex-row-reverse flex-nowrap sm:flex-nowrap max-w-3xl w-full items-center">
          <div className="w-36 sm:w-64 justify-end h-full flex flex-rows items-center">
            {/*<img
              className="h-36 w-36 sm:h-full sm:w-64 object-cover"
              src={img}
            />*/}
            <GatsbyImage
              image={img.gatsbyImageData}
              className="h-36 w-36 sm:h-full sm:w-64 object-cover"
              alt={img.title} /> 
          </div>
          <div className="flex flex-row justify-start w-3/4 pl-4">
            <div className="sm:p-5 flex flex-col">
              <div className="font-bold text-abx-dark-blue hover:text-abx-pink text-lg sm:text-2xl w-full">
                <h1><Link to={ "/blog/" + (title.replace(/[^a-zA-Z0-9 ]/g, "")).replace(/\s+/g, "-").toLowerCase() }>{ title }</Link></h1>
              </div>
              <div className="w-full text-abx-blue text-xs sm:text-sm mt-2">
                <small>{ date }</small>
              </div>
              <div className="py-5 text-sm sm:text-md hidden sm:block text-abx-dark-grey">
                { description }
              </div>
              <div className="flex flex-rows items-center">
                <div className="w-full pb-3 mt-2 text-xs sm:text-md">
                  <Link to={ "/blog/" + (title.replace(/[^a-zA-Z0-9 ]/g, "")).replace(/\s+/g, "-").toLowerCase() }>
                    <button className="bg-abx-blue hover:bg-abx-pink text-white sm:p-3 p-2 rounded">
                      Read Now
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostCard;
